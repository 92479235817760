import React, { useState, useRef, useCallback, useEffect } from 'react'
import DealerSearch from '../modules/DealerSearch'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  MarkerClusterer,
  InfoWindow,
} from '@react-google-maps/api'

import { apiURL } from 'gatsby-theme-kn/src/utils/partner_portal_spa'
import useAxiosPartnerPortal from 'gatsby-theme-kn/src/hooks/useAxiosPartnerPortal'
import mapStyles from '../../utils/mapStyles'

import { useTranslation } from 'react-i18next'

import cluster1 from '../../assets/images/marker-cluster/marker-cluster1.png'
import cluster2 from '../../assets/images/marker-cluster/marker-cluster2.png'
import cluster3 from '../../assets/images/marker-cluster/marker-cluster3.png'
import cluster4 from '../../assets/images/marker-cluster/marker-cluster4.png'
import cluster5 from '../../assets/images/marker-cluster/marker-cluster5.png'

import pplogo from '../../assets/images/Logo_Premium-Partner-2Sterne-2024.png'
import kplogo from '../../assets/images/Logo_Koenigs_Partner-2Sterne-2024.png'

const containerStyle = {
  display: 'block',
  width: '66.666vw',
  height: '80vh',
}

const center = {
  lat: 50.2314798,
  lng: 8.7610036,
}

const options = {
  styles: mapStyles,
}

const clusterStyles = [
  {
    url: cluster1,
    height: 53,
    width: 53,
    textSize: 16,
    textColor: '#df542c',
  },
  {
    url: cluster2,
    height: 53,
    width: 53,
    textSize: 16,
    textColor: '#df542c',
  },
  {
    url: cluster3,
    height: 53,
    width: 53,
    textSize: 16,
    textColor: '#df542c',
  },
  {
    url: cluster4,
    height: 53,
    width: 53,
    textSize: 16,
    textColor: '#df542c',
  },
  {
    url: cluster5,
    height: 53,
    width: 53,
    textSize: 16,
    textColor: '#df542c',
  },
]

const DealerMap = ({
  searchQuery,
  coords,
  byInput,
  byCoords,
  error,
  title,
}) => {
  const [mapLoaded, setMapLoaded] = useState(false)
  const [zoom, setZoom] = useState(11)
  const [formattedAddress, setFormattedAddress] = useState('')
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [visibleMarkers, setVisibleMarkers] = useState(null)
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  })

  const { t } = useTranslation()

  const [dealerData, setDealerData] = useState(false)
  const { data, loading, e } = useAxiosPartnerPortal(
    apiURL('kn_dealer_data.json'),
    {},
    'get'
  )
  useEffect(() => {
    if (data) {
      setDealerData(data)
    }
  }, [data])

  const mapRef = useRef()
  const onMapLoad = useCallback((map) => {
    mapRef.current = map
    setMapLoaded(true)
  }, [])

  // pan map to search input
  const geoByInput = useCallback(() => {
    if (mapLoaded) {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode(
        { address: decodeURIComponent(searchQuery) },
        async (results) => {
          try {
            const result = results[0]
            const address = result.formatted_address
            const lat = await result.geometry.location.lat()
            const lng = await result.geometry.location.lng()
            setFormattedAddress(address)
            panTo({ lat, lng })
          } catch (error) {
            console.error(error)
          }
        }
      )
    }
  }, [mapLoaded, searchQuery])

  // pan map to geolocation
  const geoByCoords = useCallback(() => {
    if (mapLoaded) {
      const geocoder = new google.maps.Geocoder()
      geocoder.geocode({ location: coords }, async (results) => {
        try {
          const result = results[0]
          const address = result.formatted_address
          setFormattedAddress(address)
          panTo({ lat: coords.lat, lng: coords.lng })
        } catch (error) {
          /* console.log(error) */
        }
      })
    }
  }, [mapLoaded, coords])

  const panTo = useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng })
  }, [])

  // get all markers in current bounds when map is idle
  let markers = []
  const getVisibleMarkers = useCallback(() => {
    markers.length > 0 && markers.splice(0, markers.length)
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds()
      const center = bounds.getCenter()
      const centerPosition = {
        lat: parseFloat(center.lat()),
        lng: parseFloat(center.lng()),
      }
      dealerData &&
        dealerData?.dealer.map((marker) => {
          const markerPosition = {
            lat: parseFloat(marker.lat),
            lng: parseFloat(marker.lng),
          }
          if (bounds && bounds.contains(markerPosition)) {
            // Die Entfernung zum 'center' manuell berechnen
            //    und als 'distance' in den Marker schreiben
            let delta =
              markerPosition.lat -
              centerPosition.lat +
              (markerPosition.lng - centerPosition.lng)
            markers.push(Object.assign(marker, { distance: delta }))
          }
        })

      // nach 'distance' sortiert zurückgeben
      setVisibleMarkers(
        markers.sort(function (a, b) {
          return b.distance - a.distance
        })
      )
    }
  })

  useEffect(() => {
    if (mapLoaded) {
      byInput ? geoByInput() : byCoords && coords ? geoByCoords() : null
    }
  }, [geoByInput, geoByCoords, mapRef])

  // if the user blocked tracking the default map will be rendered
  if (loadError) return <div>error</div>
  if (!isLoaded) return <div>loading</div>
  if (isLoaded)
    return (
      <>
        <section className="skewed-block">
          <div className="skewed-block-space"></div>
        </section>
        <section className="dealer-map">
          <div className="dealer-map-header">
            <div className="dealer-map-header-container">
              <div>
                <h1>{title}</h1>
                <i className="fas fa-compass"></i>{' '}
                {formattedAddress && <span>{formattedAddress}</span>}
              </div>
            </div>
          </div>
          <div className="dealer-map-search">
            <div className="dealer-map-search-container">
              <DealerSearch />
            </div>
          </div>
          <div className="dealer-map">
            <div className="dealer-map-location">
              <div className="dealer-map-location-row">
                <div className="dealer-map-location-list">
                  {visibleMarkers &&
                    visibleMarkers.map((marker, index) => (
                      <div
                        className="dealer-map-location-list-item"
                        key={index}
                        onClick={() => setSelectedMarker(marker)}
                      >
                        <span className="dealer-map-location-list-name">
                          {marker.name1}
                        </span>
                        <span className="dealer-map-location-list-address">
                          {marker.street} <br />
                          {marker.zip} {marker.location}
                        </span>
                        <span className="dealer-map-location-list-contact">
                          {t('generic.spa.dealer_map.contact')}:{' '}
                          {marker.contact}
                        </span>
                        <a
                          className="dealer-map-location-list-phone"
                          href={`tel:${marker.phone}`}
                        >
                          <i className="fas fa-phone fa-fw"></i>&nbsp;
                          {marker.phone}
                        </a>
                        {marker.fax && (
                          <a
                            className="dealer-map-location-list-fax"
                            href={`fax:${marker.fax}`}
                          >
                            <i className="fas fa-fax fa-fw"></i>&nbsp;
                            {marker.fax}
                          </a>
                        )}
                        <a
                          className="dealer-map-location-list-email"
                          href={`mailto:${marker.email}`}
                        >
                          <i className="far fa-envelope fa-fw"></i>&nbsp;
                          {marker.email}
                        </a>
                        <a
                          className="dealer-map-location-list-website"
                          href={
                            marker.url.indexOf('https://') !== -1
                              ? ''
                              : 'https://' + marker.url
                          }
                          target="_blank"
                        >
                          <i className="fas fa-link fa-fw"></i>&nbsp;
                          {marker.url}
                        </a>
                        <a
                          className="dealer-map-location-list-googlemaps"
                          target="_blank"
                          href={`https://www.google.de/maps/dir/${marker.street},${marker.zip},${marker.location}/@${marker.lat},${marker.lng}`}
                        >
                          <i className="fal fa-map-marker-alt fa-fw"></i>
                          &nbsp;{t('generic.spa.dealer_map.route')}
                        </a>
                        {marker.logo_url?.length > 0 && (
                          <img
                            className="dealer-map-location-list-logo"
                            src={marker.logo_url}
                          />
                        )}
                      </div>
                    ))}
                </div>
                <div className="dealer-map-location-map">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={zoom}
                    options={options}
                    onLoad={onMapLoad}
                    onIdle={getVisibleMarkers}
                  >
                    <MarkerClusterer maxZoom={11} styles={clusterStyles}>
                      {(clusterer) =>
                        dealerData &&
                        dealerData?.dealer.map((marker, index) => {
                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: parseFloat(marker.lat),
                                lng: parseFloat(marker.lng),
                              }}
                              icon={{
                                path: 'M2,2 2,29 29,34 34,2z',
                                scale: 0.5,
                                strokeWeight: 2.0,
                                strokeColor: '#FFFF',
                                strokeOpacity: 1,
                                fillColor: '#FF6E3D',
                                fillOpacity: 1.0,
                              }}
                              onClick={() => {
                                setSelectedMarker(marker)
                              }}
                              clusterer={clusterer}
                            />
                          )
                        })
                      }
                    </MarkerClusterer>
                    {selectedMarker ? (
                      <InfoWindow
                        position={{
                          lat: parseFloat(selectedMarker.lat),
                          lng: parseFloat(selectedMarker.lng),
                        }}
                        onCloseClick={() => {
                          setSelectedMarker(null)
                        }}
                      >
                        {/* TODO: Codesplitting */}
                        <div className="dealer-map-details" id="dealer-211">
                          <h4>
                            <div>{selectedMarker.name1}</div>
                          </h4>
                          <div className="dealer-map-details-group">
                            <div>{selectedMarker.street}</div>
                            <div>{`${selectedMarker.zip} ${selectedMarker.location}`}</div>
                          </div>
                          <div className="dealer-map-details-group">
                            <div>
                              <span className="">
                                {t('generic.spa.dealer_map.contact')}:{' '}
                                {selectedMarker.contact}
                              </span>
                            </div>
                            <div title="Tel">
                              <a
                                className="dealer-map-details-phone-link"
                                href={`tel:${selectedMarker.phone}`}
                              >
                                <i className="fas fa-phone fa-fw"></i>&nbsp;
                                {selectedMarker.phone}
                              </a>
                            </div>
                            <div title="Fax">
                              <a
                                className="dealer-map-details-fax-link"
                                href={`fax:${selectedMarker.fax}`}
                              >
                                <i className="fas fa-fax fa-fw"></i>&nbsp;
                                {selectedMarker.fax}
                              </a>
                            </div>
                          </div>

                          <div className="dealer-map-details-group">
                            <div>
                              <a
                                className="dealer-map-details-web-link"
                                href={`mailto:${selectedMarker.email}`}
                              >
                                <i className="far fa-envelope fa-fw"></i>
                                &nbsp;{selectedMarker.email}
                              </a>
                            </div>
                            <div>
                              <a
                                className="dealer-map-details-web-link"
                                target="_blank"
                                href={
                                  selectedMarker.url.indexOf('https://') !== -1
                                    ? ''
                                    : 'https://' + selectedMarker.url
                                }
                              >
                                <i className="fas fa-link fa-fw"></i>
                                &nbsp;{selectedMarker.url}
                              </a>
                            </div>
                          </div>
                          <div className="dealer-map-details-group">
                            <div>
                              <a
                                className="dealer-map-details-direction-link"
                                target="_blank"
                                href={`https://www.google.de/maps/dir/${selectedMarker.street},${selectedMarker.zip},${selectedMarker.location}/@${selectedMarker.lat},${selectedMarker.lng}`}
                              >
                                <i className="fal fa-map-marker-alt fa-fw"></i>
                                &nbsp;{t('generic.spa.dealer_map.route')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </InfoWindow>
                    ) : null}
                  </GoogleMap>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    )
}

export default DealerMap
